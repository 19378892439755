/* eslint-disable @typescript-eslint/no-unused-vars */
const filterOutNulls = (object: any): boolean => {
  if (!object) return false

  const keys = Object.keys(object)
  for (const key of keys) {
    if (object[key] === null || object[key] === undefined) {
      return false
    }

    if (typeof object[key] === 'object' && object[key].constructor === Object) {
      return filterOutNulls(object[key])
    }
  }

  return true
}

export const filterOutObject = (obj: object) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined)
  )
}

export default filterOutNulls
