import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useLocation = (pure: boolean = false) => {
  const locationPath =
    typeof window !== 'undefined' ? window.location.pathname : '/'
  const { i18n } = useTranslation()
  const [pathname, setPathname] = useState(locationPath)

  useEffect(() => {
    const location = window.location.pathname

    if (pure) {
      let pureLocation = location
      for (const lang of i18n.languages) {
        pureLocation = pureLocation.replace(`/${lang}/`, '/')
      }
      setPathname(pureLocation)
    } else {
      setPathname(location)
    }
  }, [locationPath])

  return pathname
}

export default useLocation
